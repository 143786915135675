<template>
    <v-form>
        <v-container>
          <v-alert
              border="right"
              colored-border
              type="error"
              v-if="error"
              elevation="2">
            E-Mail or ID Number already registered
          </v-alert>
            <v-row>
                <v-col cols="12" sm="6" md="6" class="formItem">
                    <v-text-field
                            label="Name*"
                            name="login"
                            prepend-icon="person"
                            type="text"
                            v-model="name"
                            :rules="rules"/>
                </v-col>
                <v-col cols="12" sm="6" md="6" class="formItem">
                    <v-text-field
                            label="Lastname*"
                            name="login"
                            prepend-icon="person"
                            type="text"
                            v-model="lastname"
                            :rules="rules"/>
                </v-col>
                <v-col cols="12" sm="6" md="6" class="formItem">
                    <v-text-field
                            label="Email*"
                            name="login"
                            type="text"
                            v-model="mail"
                            prepend-icon="mdi-email"
                            :rules="rules"/>
                </v-col>
                <v-col cols="12" sm="6" md="6" class="formItem">
                    <v-text-field
                            label="Phone*"
                            name="login"
                            v-model="phone"
                            prepend-icon="phone"
                            type="text"/>
                </v-col>
                <v-col cols="12" sm="6" md="6" class="formItem">
                    <v-text-field
                            label="Address*"
                            name="login"
                            v-model="address"
                            prepend-icon="domain"
                            type="text"/>
                </v-col>
                <v-col cols="12" sm="6" md="6" class="formItem">
                    <v-select
                            prepend-icon="flag"
                            v-model="country"
                            :items="countries"
                            label="Country*"/>
                </v-col>
                <v-col cols="12" v-if="country=='Other'" sm="12" md="12" class="formItem">
                    <v-text-field
                            label="Specify your country"
                            name="login"
                            v-model="otherCountry"
                            prepend-icon="flag"
                            type="text"/>
                </v-col>
                <v-col cols="12" sm="6" md="6" class="formItem">
                    <v-text-field
                            label="ID Number*"
                            name="name"
                            v-model="IDN"
                            prepend-icon="mdi-account-card-details-outline"
                            type="text"
                            :rules="rules"/>
                </v-col>
                <v-col cols="12" sm="6" md="6" class="formItem">
                    <v-text-field
                            label="BirthDate*"
                            name="login"
                            type="date"
                            v-model="bdate"
                            prepend-icon="mdi-calendar"/>
                </v-col>
                <v-col cols="12" sm="6" md="6" class="formItem">
                    <v-text-field
                            label="Linkedin"
                            name="name"
                            v-model="inURL"
                            prepend-icon="mdi-linkedin"
                            type="text"/>
                </v-col>
                <v-col cols="12" class="formItem">
                    <v-checkbox class="privacePolicy" v-bind:class="{required:required}" v-model="allow"
                                label="I Read and accept the privacy Policy"/>
                    <label style="position: relative; top: -15px;" class="v-label theme--light">You can find the privacy
                        policy in <a :href="privacylink" target="_blank" rel="nofollow"> this link</a></label>
                </v-col>
                <v-col cols="12" class="formItem">
                    <v-btn color="#213B87" small style="width: 30%;margin-left: 35%;margin-right: 35%;" @click="createStudent">
                        Register
                    </v-btn>
                </v-col>
            </v-row>
        </v-container>
    </v-form>
</template>

<script>
    export default {
        name: "studentForm",
        props:['alert'],
        data() {
            return {
                name: '',
                lastname:'',
                phone: '',
                mail: '',
                address: '',
                country: '',
                sector: '',
                otherCountry:'',
                IDN:'',
                bdate:'',
                inURL:'',
                privacylink: 'https://erovet.eu/privacy-policy/form-policy-spain/',
                countries: ['Spain', 'Portugal', 'Turkey', 'Greece', 'Lithuania', 'Italy', 'France', 'United Kingdom', 'Other'],
                allow: false,
                rules: [
                    value => !!value || 'Required.',
                    value => (value && value.length >= 3) || 'Min 3 characters',
                ],
                required: false,
              error:false
            }
        },
        methods: {
            createStudent() {
              this.error = false;
                let url = this.base_url + '/home/newstudent';
                let formData = new FormData();
                formData.set('name', this.name);
                formData.set('lastname',this.lastname);
                formData.set('phone', this.phone);
                formData.set('email', this.mail);
                formData.set('address', this.address);
                formData.set('cid', 1);
                formData.set('sid', 1);
                formData.set('idn', this.IDN);
                formData.set('bdate', this.bdate);
                formData.set('inURL', this.inURL);
                this.axios.post(url, formData).then(() => {
                    this.$router.push('/login');
                }).catch(err=>{
                  if(err.response.data.ecode)
                    this.error = true;
                })
            }
        },
        watch: {
            country: function (val) {
                switch (val) {
                    case 'Spain':
                        this.privacylink = 'https://erovet.eu/privacy-policy/form-policy-spain/';
                        break;
                    case 'Portugal':
                        this.privacylink = 'https://erovet.eu/privacy-policy/form-policy-portugal/';
                        break;
                    case 'Turkey':
                        this.privacylink = 'https://erovet.eu/privacy-policy/form-policy-coordinator-2/';
                        break;
                    case 'Greece':
                        this.privacylink = 'https://erovet.eu/privacy-policy/form-policy-greece/';
                        break;
                    case 'Lithuania':
                        this.privacylink = 'https://erovet.eu/privacy-policy/form-policy-lithuania/';
                        break;
                    case 'Italy':
                        this.privacylink = 'https://erovet.eu/privacy-policy/form-policy-italy/';
                        break;
                    case 'France':
                        this.privacylink = '';
                        break;
                    case 'United Kingdom':
                        this.privacylink = '';
                        break;

                }
            }
        }
    }
</script>

<style scoped>
    .formItem {
        float: left;
    }

    .my-2 {
        display: inline-grid;
        margin: 1em;
    }

    .required label {
        color: red !important;
    }
</style>