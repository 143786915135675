<template>
    <v-content class="loginContent">
        <v-container class="fill-height"
                     fluid>
            <v-row align="center"
                   justify="center">
                <v-col cols="9">
                    <v-card class="elevation-12">
                        <v-toolbar color="#213B87"
                                   dark
                                   flat>
                            <v-toolbar-title>
                                <router-link to="/">
                                    <img src="../assets/images/WEB-BOT-EROVET.png" width="175" alt="logoerovet">
                                </router-link>
                            </v-toolbar-title>
                            <v-spacer/>
                        </v-toolbar>
                        <v-card-text>
                            <v-select v-if="alert==false"
                                    style="margin-left: 1em; margin-right: 1em;"
                                    :items="profiles"
                                    label="Select Profile"
                                    v-model="profile"/>
                            <student-form v-if="profile=='student'" :alert="alert"/>
                            <company-form v-if="profile=='company'" :alert="alert"/>
                        </v-card-text>
                    </v-card>
                </v-col>
            </v-row>
        </v-container>
    </v-content>
</template>

<script>
    import studentForm from "../components/Register/studentForm";
    import companyForm from "../components/Register/companyForm";

    export default {
        name: "Register",
        components: {studentForm, companyForm},
        data() {
            return {
                profile: '',
                profiles: ['company', 'student'],
                alert:false
            }
        }
    }
</script>