<template>
    <v-form v-if="alert==false">
        <v-container>
            <v-row>
                <v-col cols="12" sm="6" md="6" class="formItem">
                    <v-text-field
                            label="Company Name*"
                            name="login"
                            prepend-icon="mdi-account-card-details-outline"
                            type="text"
                            v-model="name"
                            :rules="rules"/>
                </v-col>
              <v-col cols="12" sm="6" md="6" class="formItem">
                <v-text-field
                    label="C.I.F."
                    name="cif"
                    prepend-icon="mdi-account-card-details-outline"
                    type="text"
                    v-model="cif"
                    :rules="rules"/>
              </v-col>
                <v-col cols="12" sm="6" md="6" class="formItem">
                    <v-text-field
                            label="Address*"
                            name="login"
                            prepend-icon="domain"
                            type="text"
                            v-model="address"
                            :rules="rules"/>
                </v-col>
                <v-col cols="12" sm="6" md="6" class="formItem">
                    <v-text-field
                            label="E-Mail*"
                            name="login"
                            prepend-icon="mdi-mail"
                            type="text"
                            v-model="mail"
                            :rules="rules"/>
                </v-col>
                <v-col cols="12" sm="6" md="6" class="formItem">
                    <v-text-field
                            label="Phone*"
                            name="login"
                            prepend-icon="phone"
                            type="text"
                            v-model="phone"
                            :rules="rules"/>
                </v-col>
                <v-col cols="12" sm="6" md="6" class="formItem">
                    <v-select
                            prepend-icon="flag"
                            v-model="country"
                            :items="countries"
                            label="Country*"/>
                </v-col>
                <v-col cols="12" sm="6" md="6" class="formItem">
                    <v-text-field
                            label="WebPage"
                            name="login"
                            v-model="page"
                            prepend-icon="mdi-web"
                            type="text"/>
                </v-col>
                <v-col cols="12" sm="6" md="6" class="formItem">
                    <v-text-field
                            label="Contact Email*"
                            name="name"
                            v-model="contactMail"
                            prepend-icon="mdi-account-card-details-outline"
                            type="text"
                            :rules="rules"/>
                </v-col>
                <v-col cols="12" sm="6" md="6" class="formItem">
                    <v-text-field
                            label="Contact Phone"
                            name="name"
                            v-model="contactPhone"
                            prepend-icon="mdi-account-card-details-outline"
                            type="text"/>
                </v-col>
                <v-col cols="12" sm="6" md="6" class="formItem">
                    <v-select
                            prepend-icon="mdi-server"
                            :items="sectors"
                            v-model="sector"
                            label="Sector*"/>
                </v-col>
                <v-col cols="12" sm="6" md="6" class="formItem">
                    <v-text-field
                            label="Number of workers"
                            name="name"
                            v-model="nWorker"
                            prepend-icon="mdi-account-multiple"
                            type="number"/>
                </v-col>
                <v-col cols="12" sm="12" md="12" class="formItem">
                    <v-text-field
                            label="Additional Info"
                            name="name"
                            v-model="info"
                            prepend-icon=" mdi-information-outline"
                            type="text"/>
                </v-col>
                <v-col cols="12" class="formItem">
                    <v-checkbox class="privacePolicy" v-bind:class="{required:required}" v-model="allow"
                                label="I Read and accept the privacy Policy"/>
                    <label style="position: relative; top: -15px;" class="v-label theme--light">You can find the privacy
                        policy in <a :href="privacylink" target="_blank" rel="nofollow"> this link</a></label>
                </v-col>
                <v-col cols="12" class="formItem">
                    <v-btn small style="width: 30%;margin-left: 35%;margin-right: 35%;" v-on:click="createCompany" color="#213B87">Register</v-btn>
                </v-col>
            </v-row>
        </v-container>
    </v-form>
    <v-alert v-else
            color="rgb(33, 59, 135)"
            dark
            icon="mdi-firework"
            dense>
        Your request has been successfully registered, we will contact you shortly to confirm your registration

    </v-alert>
</template>

<script>
    export default {
        name: "companyForm",
        props:['alert'],
        data() {
            return {
                name: '',
              cif:'',
                address: '',
                phone: '',
                mail: '',
                country: '',
                page: '',
                contactMail: '',
                contactPhone: '',
                sector: '',
                nWorker: '',
                info: '',
                privacylink: '',
                countries: ['Spain', 'Portugal', 'Turkey', 'Greece', 'Lithuania', 'Italy', 'France', 'United Kingdom'],
                sectors: ['All', 'Automation', 'Business and Administration', 'Carpentry', 'Catering and restaurant services', 'Chemistry', 'Construction', 'Electricity and Electronics',
                    'Energy and Water', 'Enviroment', 'Hairdressing and personal image', 'Health and Welfare', 'Heating and cooling', 'Hotel and tourism', 'ICT', 'Mechanics',
                    'Media', 'Metallurgy', 'Safety', 'Social Services', 'Sports', 'Tourism', 'Trade', 'Other'],
                allow: false,
                required: false,
                rules: [
                    value => !!value || 'Required.',
                    value => (value && value.length >= 3) || 'Min 3 characters',
                ],

            }
        },
        methods: {
            createCompany() {
                let url = this.base_url + '/home/newcompany';
                let formData = new FormData();
                formData.set('name', this.name);
              formData.set('cif', this.cif);
                formData.set('address', this.address);
                formData.set('postalCode', this.postalCode);
                formData.set('city', this.city);
                formData.set('phone', this.phone);
                formData.set('email', this.mail);
                formData.set('cid', 1);
                formData.set('webURL', this.page);
                formData.set('contactEmail', this.contactMail);
                formData.set('contactPhone', this.contactPhone);
                formData.set('sid', 1);
                formData.set('nWorkers', this.nWorker);
                formData.set('info', this.info);
                this.axios.post(url, formData).then(() => {
                    this.alert = true;
                })
            }
        },
        watch: {
            country: function (val) {
                switch (val) {
                    case 'Spain':
                        this.privacylink = 'https://erovet.eu/privacy-policy/form-policy-spain/';
                        break;
                    case 'Portugal':
                        this.privacylink = 'https://erovet.eu/privacy-policy/form-policy-portugal/';
                        break;
                    case 'Turkey':
                        this.privacylink = 'https://erovet.eu/privacy-policy/form-policy-coordinator-2/';
                        break;
                    case 'Greece':
                        this.privacylink = 'https://erovet.eu/privacy-policy/form-policy-greece/';
                        break;
                    case 'Lithuania':
                        this.privacylink = 'https://erovet.eu/privacy-policy/form-policy-lithuania/';
                        break;
                    case 'Italy':
                        this.privacylink = 'https://erovet.eu/privacy-policy/form-policy-italy/';
                        break;
                    case 'France':
                        this.privacylink = '';
                        break;
                    case 'United Kingdom':
                        this.privacylink = '';
                        break;
                }
            }
        }
    }
</script>

<style scoped>
    .formItem {
        float: left;
    }

    .my-2 {
        display: inline-grid;
        margin: 1em;
    }

    .required label {
        color: red !important;
    }
</style>